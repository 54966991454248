<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Is there anything else that I left off the previous question that should be included on the
        perfect CHEMISTRY LAB syllabus?
        <i>Open ended.</i>
      </p>

      <s-textarea v-model="inputs.input1" outlined />
    </v-form>
  </div>
</template>

<script>
import STextarea from '@/common/components/STextarea.vue';
import DynamicQuestionMixin from '../../mixins/dynamic-question';

export default {
  name: 'OleMissSurveyQ18',
  components: {
    STextarea,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
};
</script>
<style scoped></style>
